<template>
  <div class="px-6">
    <div class="page-header mb-6 sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h2 class="text-xl font-medium text-gray-900">Employees</h2>
        <p class="mt-1 text-sm text-gray-500">
          A list of all the Employees in the app including their name, email,
          title, status, and Google status.
        </p>
      </div>
      <div class="min-w-64 xl:w-1/5 mt-4 sm:mt-0 sm:flex-none">
        <input
          v-model="searchTerm"
          class="block w-full rounded-md border-0 py-1.5 text-base sm:text-sm/6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cogburn-red"
          id="search"
          name="search"
          type="text"
          placeholder="Search"
          @keydown="searchHandleKeydown"
        />
      </div>
    </div>
    <div v-if="loading" class="mt-2 mb-6"><loading></loading></div>
    <div v-if="!loading" class="flow-root">
      <div class="-mx-6">
        <div class="inline-block min-w-full">
          <table class="min-w-full border-separate border-spacing-0">
            <thead>
              <tr>
                <th
                  scope="col"
                  class="sticky top-0 z-10 border-b bg-white/75 backdrop-blur backdrop-filter py-3.5 pl-6 pr-3 text-left text-sm font-semibold text-gray-900 border-t border-gray-200"
                >
                  ID
                </th>
                <th
                  scope="col"
                  class="sticky top-0 z-10 border-b bg-white/75 backdrop-blur backdrop-filter px-3 py-3.5 text-left text-sm font-semibold text-gray-900 border-t border-gray-200"
                >
                  Name
                </th>
                <th
                  scope="col"
                  class="sticky top-0 z-10 border-b bg-white/75 backdrop-blur backdrop-filter px-3 py-3.5 text-left text-sm font-semibold text-gray-900 border-t border-gray-200"
                >
                  Title
                </th>
                <th
                  scope="col"
                  class="sticky top-0 z-10 border-b bg-white/75 backdrop-blur backdrop-filter px-3 py-3.5 text-left text-sm font-semibold text-gray-900 border-t border-gray-200"
                >
                  Email
                </th>
                <th
                  scope="col"
                  class="sticky top-0 z-10 border-b bg-white/75 backdrop-blur backdrop-filter px-3 py-3.5 text-left text-sm font-semibold text-gray-900 border-t border-gray-200"
                >
                  Phone Number
                </th>
                <th
                  scope="col"
                  class="sticky top-0 z-10 border-b bg-white/75 backdrop-blur backdrop-filter px-3 py-3.5 text-left text-sm font-semibold text-gray-900 border-t border-gray-200"
                >
                  Emp. Status
                </th>
                <th
                  scope="col"
                  class="sticky top-0 z-10 border-b bg-white/75 backdrop-blur backdrop-filter px-3 py-3.5 text-left text-sm font-semibold text-gray-900 border-t border-gray-200"
                >
                  Google
                </th>
                <th
                  v-if="false"
                  scope="col"
                  class="sticky top-0 z-10 border-b bg-white/75 backdrop-blur backdrop-filter relative py-3.5 pl-3 pr-4 sm:pr-0 border-t border-gray-200"
                >
                  <span class="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <template v-for="emp in employees" :key="emp.uuid">
                <tr v-if="searchFor[emp._tuuid]" class="bg-white">
                  <td
                    class="whitespace-nowrap py-4 pl-6 pr-3 text-sm font-medium text-gray-900 border-b border-gray-200"
                  >
                    {{ emp.employeeId }}
                  </td>
                  <td
                    class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 border-b border-gray-200"
                  >
                    {{ emp.preferredName || emp.legalName }}
                  </td>
                  <td
                    class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 border-b border-gray-200"
                  >
                    {{ emp.title }}
                  </td>
                  <td
                    class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 border-b border-gray-200"
                  >
                    {{ emp.preferredEmail }}
                  </td>
                  <td
                    class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 border-b border-gray-200"
                  >
                    {{ emp.phoneNumber }}
                  </td>
                  <td
                    class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 border-b border-gray-200"
                  >
                    <span
                      v-if="emp.employeeId && emp.active"
                      class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20"
                    >
                      Active
                    </span>
                    <span
                      v-if="emp.employeeId && !emp.active"
                      class="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20"
                    >
                      Terminated
                    </span>
                    <span
                      v-if="emp.isAuthNonEmployee"
                      class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20"
                    >
                      Non-Employee
                    </span>
                  </td>
                  <td
                    class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 border-b border-gray-200"
                  >
                    <span
                      v-if="emp.google && !emp.google?.suspended"
                      class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20"
                    >
                      Active
                    </span>
                    <span
                      v-if="emp.google && emp.google?.suspended"
                      class="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20"
                    >
                      Suspended
                    </span>
                  </td>
                  <td
                    v-if="false"
                    class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0"
                  >
                    <a href="#" class="text-indigo-600 hover:text-indigo-900">
                      Edit
                    </a>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import F from '../lib/fetch.js';
import UUIDv7 from '@root/uuidv7';
const { uuidv7: uuid } = UUIDv7;

import Loading from '../components/loading.vue';

let debouncedSearch = debounce(function (vm) {
  // console.log(vm.searchTerm);
  let searchTerm = vm.searchTerm.toLowerCase();
  for (let emp of vm.employees) {
    // console.log(emp._tuuid);
    if (vm.searchTerm.length === 0) {
      vm.searchFor[emp._tuuid] = true;
      continue;
    }

    let concatenatedValues = getConcatenatedValues(emp);
    vm.searchFor[emp._tuuid] = concatenatedValues.includes(searchTerm);
  }
}, 1000);

export default {
  components: {
    Loading,
  },
  data: function () {
    return {
      loading: true,
      employees: [],
      weirdos: [],
      searchTerm: '',
      searchFor: {},
    };
  },
  methods: {
    searchHandleKeydown: async function ($event) {
      let vm = this;
      if ($event.key === 'Escape') {
        vm.searchTerm = '';
      }
    },
  },
  watch: {
    searchTerm: function () {
      let vm = this;
      debouncedSearch(vm);
    },
  },
  mounted: async function () {
    let vm = this;
    Object.assign(vm, {
      loading: true,
      employees: [],
      weirdos: {},
    });

    let employees = await F.fetch('/api/admin/employees').then(function (resp) {
      return resp.json();
    });
    let weirdos = await F.fetch('/api/admin/weirdos').then(function (resp) {
      return resp.json();
    });
    // add foundation weirdos so no active employee is left out of the list
    for (let wid of Object.keys(weirdos.Foundation.weirdosById)) {
      let w = weirdos.Foundation.weirdosById[wid];
      w.employeeId = w.employee_id;
      w.foundation = { ...w };
      employees.push(w);
    }
    // console.log(weirdos);

    // give every item a temporary uuid
    for (let el of employees) {
      el._tuuid = uuid();
    }

    employees.sort(function (a, b) {
      if (a.employeeId === null && b.employeeId === null) {
        return 0; // Both are null, keep original order
      } else if (a.employeeId === null) {
        return 1; // a should come after b
      } else if (b.employeeId === null) {
        return -1; // b should come after a
      } else {
        return a.employeeId - b.employeeId; // Sort by employeeId
      }
    });

    for (let emp of employees) {
      let phoneNumber = vm.$root.getPhoneNumber(emp);
      if (phoneNumber) {
        phoneNumber = vm.$root.formatPhoneNumber(phoneNumber);
      }

      emp.isAuthNonEmployee = isAuthNonEmployee(emp);

      Object.assign(emp, {
        preferredName: vm.$root.getPreferredName(emp),
        legalName: vm.$root.getLegalName(emp),
        active: emp.foundation?.date_terminated === null ? true : false,
        preferredEmail: vm.$root.getPreferredEmail(emp),
        title: vm.$root.getEmpTitle(emp),
        phoneNumber: phoneNumber,
        review: false,
      });

      if (!emp.employeeId) {
        emp.employmentStatus = 'Other';
      }
    }

    // init searchFor
    let searchFor = {};
    for (let el of employees) {
      searchFor[el._tuuid] = true;
    }

    Object.assign(vm, {
      loading: false,
      employees: employees,
      weirdos: weirdos,
      searchFor: searchFor,
    });
  },
};

function isAuthNonEmployee(obj) {
  return (
    obj.foundation === null &&
    obj.google?.customSchemas?.Contact?.Allow_App_Sign_In === true
  );
}

function getConcatenatedValues(obj) {
  const stack = [obj];
  let result = '';

  while (stack.length > 0) {
    const current = stack.pop();

    if (typeof current === 'object' && current !== null) {
      if (Array.isArray(current)) {
        stack.push(...current);
      } else {
        stack.push(...Object.values(current));
      }
    } else if (typeof current === 'string' || typeof current === 'number') {
      result += current.toString().toLowerCase();
    }
  }

  return result;
}

function debounce(func, delay = 1000) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };
}
</script>
